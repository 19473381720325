<template>
    <div class="page">
        <HeadContent>
            <img slot="bgImg" src="../../assets/common/topTitleBg.png" style="margin-top: 70px">
            <div slot="title" style="width: 100%">{{title}}</div>
        </HeadContent>
        <div class="container">
            <div class="bbbox">
                <div class="ltitle">{{title}}</div>
                <div class="gxtime">上次更新时间：2021年4月1日</div>
                <div class="content" v-show="title=='保密承诺'">
                    <p>
                        本公司严格遵守保密制度，对在检验检测活动中所知悉的国家秘密、商业秘密和技术秘密负有保密义务，保
                        护客户的合法权益，对客户所提供的资料、样品及检测数据、结果，不得用于本单位或个人的技术咨询和技
                        术服务，也不得向其他单位或个人提供。 从客户以外渠道（如投诉人、监管机构）获取有关客户的信息，会
                        在客户和实验室之间保密，除非信息的提供方同意，我方不会透露信息的提供方（来源）。
                    </p>

                    <p>
                        为确保客户的技术资料、检测结果、机密信息不泄露，保证客户的知识产权不受侵犯，本公司已制定《保密
                        和保护所有权控制程序》，并做如下保密承诺：
                    </p>

                    <ul>
                        <li>严格遵守国家和公司有关保密的各种规定；</li>
                        <li>对客户信息、样品信息、检测数据或报告予以保密，未经客户的书面许可，不得透露予第三方；</li>
                        <li>不得使用客户拥有的知识产权进行任何形式的侵害客户利益的行为；</li>
                        <li>对在检测活动中获取的国家秘密、商业秘密和技术秘密予以保密，禁止任何形式的泄露行为；</li>
                        <li>公司所有人员认真学习、坚决贯彻执行《保密和保护所有权控制程序》</li>
                    </ul>
                </div>
                <div class="content" v-show="title=='公正性声明'">
                    为保证本公司检测工作的公正、独立和诚实，确保向社会提供的结果准确、 可靠、具备法律效力，

                    <div>
                        特郑重声明如下：
                    </div>

                    <p>
                        1.本公司是独立法人资格的具有独立开展检测业务的检测机构，严格按照国家有关法律、法规、强制性标准和合
                        同规定的检验标准、方法或客户约定的方法 进行检验，对所出具的检测报告负责，并承担相应法律责任。
                    </p>

                    <p>
                        2.本公司以检测数据和技术标准为唯一依据，不受内部或外部的任何行政干预，亦不受任何来自不正当的商
                        业、财政以及其他方面的压力和影响，不从事影响公正地位的任何活动，确保检验检测数据、结果的真实、客
                        观、准确和可追溯。
                    </p>

                    <p>
                        3.本公司及其人员不得与其从事的检测和活动以及出具的数据和结果存在利益关系；不得参与任何有损于检测
                        判断的独立性和诚信度的活动；不得参与和检测项目或者类似的竞争性项目有关系的产品设计、研制、生产、
                        供应、安装、使用或者维护活动。不得向社会推荐产品, 开展产品监制、监销的违法行为。不得向委托方、利益
                        相关方索取不正当利益。
                    </p>

                    <p>
                        4.本公司严格遵守保密制度，对在检验检测活动中所知悉的国家秘密、商业秘密和技术秘密负有保密义务，保
                        护客户的合法权益，对客户所提供的资料、样品及检测数据、结果，不得用于本单位或个人的技术咨询和技术
                        服务，也不得向其他单位或个人提供。 从客户以外渠道（如投诉人、监管机构）获取有关客户的信息，会在客
                        户和实验室之间保密，除非信息的提供方同意，我方不会透露信息的提供方（来源）。
                    </p>

                    <p>
                        5.本公司不录用同时在两个及以上检验检测机构从业的人员。
                    </p>

                    <p>
                        6.本公司接受国家及地方市场监管部门、实验室认可机构或客户和其它相关方对其公正性的监督。
                    </p>
                </div>
                <div class="content" v-show="title=='服务承诺'">
                    为了满足客户、法定管理机构及评审机构需求，本公司承诺如下：
                    <p>
                        1.管理工作和检验检测工作符合《检验检测机构资质认定能力评价 检验检测机构通用要求》、《食品检验机构
                        资质认定条件》及《检测和校准实验室能力认可准则》的要求，严格遵守技术标准和检验检测工作程序。
                    </p>

                    <div>
                        2.满足客户的需要：
                    </div>


                    <ul style="margin-top: 0">
                        <li>不受来自权利、商业、利益等方面的不良干预，确保工作行为的公正性；</li>
                        <li>数据和结果准确可靠，结论科学、公正、合理；</li>
                        <li>一视同仁，对所有客户提供相同的服务；</li>
                        <li>严格执行收费标准，不乱收取不合理的费用；</li>
                        <li>及时处理客户的投诉及申诉，在 5 个工作日内作出令客户满意的答复；</li>
                        <li>对对客户的样品、技术资料、数据、信息及有关结果或结论保密，未经客户同意，不得向任何第三方泄露；</li>
                        <li>愿承担给客户造成损失的赔偿，愿承担经济和法律责任。</li>
                    </ul>

                    <div>3.满足法定管理机构的需求：</div>
                    <ul>
                        <li>遵纪守法，严格执行国家相关法律法规、政策的要求；</li>
                        <li>严格执行管理部门的相关制度及管理办法的要求；</li>
                        <li>诚实信用，恪守职业道德，承担社会责任。</li>
                    </ul>

                    <div>4.满足评审机构的需求：</div>

                    <ul>
                        <li>加强与评审机构的联系，将得到的文件或信息识别后加以满足；</li>
                        <li>参加认证机构的相关活动，如能力验证或实验室间比对活动、有关文件或准则的宣贯活动；</li>
                        <li>缴纳相关费用。</li>
                    </ul>
                </div>
            </div>

        </div>
    </div>

</template>

<script>
    export default {
        name: "Declaration",
        data() {
            return {
                title: this.$route.query.title
            }
        },
        created() {

        }
    }
</script>

<style scoped lang="scss">
    li {
        list-style: none
    }

    li:before {
        content: "";
        width: 6px;
        height: 6px;
        display: inline-block;
        border-radius: 50%;
        background: #1320E8;
        vertical-align: middle;
        margin-right: 14px;
    }

    .bbbox {
        box-sizing: border-box;
        width: 100%;
        padding: 0 100px;
    }

    .ltitle {
        margin-top: 100px;
        font-size: 36px;
        font-weight: 500;
        color: #333333;
    }

    .gxtime {
        margin: 36px 0;
        font-size: 18px;
        font-weight: 400;
        color: #333333;
    }

    .content {
        font-size: 20px;
        font-weight: 400;
        color: #606060;
        line-height: 34px;
    }

</style>
